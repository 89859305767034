import styled from "@emotion/styled";
import React, { createRef, FunctionComponent, useEffect } from "react";

const src = "https://utteranc.es/client.js";
const repo = "westhyun/comment";

type UtterancesAttributesType = {
  src: string;
  repo: string;
  "issue-term": string;
  label: string;
  theme: string;
  crossorigin: string;
  async: string;
};

const StyledComment = styled.section`
  .utterances {
    max-width: 640px;
  }

  @media (max-width: 640px) {
    margin: 0 2rem;
  }
`;

const Comment: FunctionComponent = function () {
  const element = createRef<HTMLDivElement>();

  useEffect(() => {
    if (element.current === null) return;

    const utterances: HTMLScriptElement = document.createElement("script");

    const attributes: UtterancesAttributesType = {
      src,
      repo,
      "issue-term": "pathname",
      label: "Comment",
      theme: `github-light`,
      crossorigin: "anonymous",
      async: "true",
    };

    Object.entries(attributes).forEach(([key, value]) => {
      utterances.setAttribute(key, value);
    });

    element.current.appendChild(utterances);
  }, []);

  return <StyledComment ref={element} />;
};

export default Comment;
