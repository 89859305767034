import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";

import { Link } from "gatsby";
import { PostFrontmatterType } from "../../@types/PostItem.types";

type PostItemProps = PostFrontmatterType & {
  link: string;
};

const PostItemWrapper = styled(Link)`
  display: flex;
  /* flex-wrap: row wrap; */
  flex-direction: column;
  /* align-items: flex-end; */
  transition: 0.3s box-shadow;
  cursor: pointer;
  justify-content: space-between;
  grid-gap: 5px;

  /* :hover a::after {
    width: 100%;
    transition: width 0.2s;
  } */

  :hover .title {
    text-decoration: underline 0.06em rgba(0, 0, 0, 0);
    text-decoration-color: rgba(0, 0, 0, 1);
    text-underline-offset: 0.2em;
  }

  :hover .category {
    background: #282828;
    color: #f5f5f5;
    transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`;

const PostItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 112px;
  padding-bottom: 33px;
  grid-gap: 10px;
  width: 100%;
  /* align-items: flex-end; */
  border-bottom: 1px solid #282828;
  @media (max-width: 640px) {
    height: 103px;
  }
`;

const ThumbnailImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 0.5rem;
  object-fit: cover;
`;

const Title = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  overflow-wrap: break-all;
  font-size: 22px;
  font-weight: 600;
  position: relative;
  flex-direction: row-reverse;
  color: #282828;
  margin-bottom: 0.3rem;

  /* &::after {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: 0px;
    background: #282828;
  } */

  @media (max-width: 640px) {
    font-size: 20px;
  }
`;

// const Date = styled.div`
//   font-size: 14px;
//   font-weight: 400;
//   opacity: 0.7;
//   margin-top: 6px;
//   opacity: 0.5;
// `

const Category = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CategoryItem = styled.div`
  padding: 3px 8px;
  border-radius: 3px;
  border: 1px solid #282828;
  font-size: 10px;
  font-weight: 400;
  color: #282828;
`;

const Summary = styled.div`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  overflow-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 14px;
  opacity: 0.8;

  @media (max-width: 640px) {
    font-size: 14px;
  }
`;

const PostItem: FunctionComponent<PostItemProps> = function ({
  title,
  categories,
  summary,
  // thumbnail: { publicURL },
  link,
}) {
  return (
    <PostItemContent>
      <PostItemWrapper to={link}>
        <Category>
          {categories.map((category) => (
            <CategoryItem className="category" key={category}>
              {category}
            </CategoryItem>
          ))}
        </Category>
        <Title className="title">{title}</Title>
        {/* <Date>{date}</Date> */}
        <Summary>{summary}</Summary>
        {/* <ThumbnailImage src={publicURL} alt="ThumbnailImage" /> */}
      </PostItemWrapper>
    </PostItemContent>
  );
};

export default PostItem;
