import styled from "@emotion/styled";
import GlobalStyle from "components/common/GlobalStyles";
import ProgressBar from "../hooks/ProgressBar";

const ResumeWrapper = styled.div`
  * {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Spoqa Han Sans Neo", "Helvetica Neue", sans-serif;
    ::selection {
      background-color: #f5f5f5;
      color: #595959;
    }
  }
  max-width: 45rem;
  margin: 0 auto;
  /* width: 100%; */
  /* display: flex; */
  /* justify-content: center; */
  padding: 6rem 1rem 0 1rem;

  @media (max-width: 640px) {
    padding: 4rem 1rem 0 1rem;
  }

  h1 {
    color: black;
    font-size: 6rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 3rem;

    @media (max-width: 640px) {
      font-size: 3rem;
      margin-bottom: 2rem;
    }
  }
`;

const Section = styled.div`
  width: 45rem;
  padding: 1rem 0;
  margin-bottom: 4.5rem;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 3rem;
  }

  p {
    font-size: 1.75rem;
    line-height: 1.8;
  }

  @media (max-width: 640px) {
    width: 100%;
    margin-bottom: 3rem;

    h2 {
      font-size: 1.8rem;
      margin-bottom: 1.5rem;
    }

    p {
      font-size: 1.2rem;
    }
  }
`;

const Main = styled(Section)``;

const Article = styled(Section)`
  display: flex;
  font-family: "Noto Sans KR", sans-serif;

  h3 {
    font-weight: 700;
    font-size: 1.4rem;
    margin-bottom: 1rem;
    padding-bottom: 0.25rem;
  }
  @media (max-width: 640px) {
    display: block;
  }
  :last-child {
    border-bottom: none;
  }
`;

const Header = styled.div`
  margin-bottom: 2rem;

  h3 {
    font-size: 1.5rem;
  }
  width: 60%;
  padding-right: 1.5rem;

  p {
    font-size: 1rem;
    &:last-child {
      margin-bottom: 1.1rem;
    }
  }

  a {
    font-size: 1.5rem;
    :hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 640px) {
    h3 {
      font-size: 1.3rem;
    }
    p {
      font-size: 0.8rem;
    }
    dl {
      font-size: 0.8rem;
    }
    a {
      font-size: 1rem;
    }
  }
`;

const Content = styled.div`
  margin-bottom: 2rem;
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    margin-top: 2rem;
    font-size: 1.2rem;
  }
  p {
    font-size: 1.2rem;
    line-height: 1.5;
  }
  a {
    font-size: 1.2rem;
    :hover {
      text-decoration: underline;
    }
  }
  li {
    margin-bottom: 1rem;
  }
  @media (max-width: 640px) {
    h4 {
      font-size: 0.8rem;
      margin-top: 1rem;
    }
    h3 {
      font-size: 1rem;
      margin-bottom: 0;
    }
    p {
      font-size: 1rem;
    }
    a {
      font-size: 1rem;
    }
    li {
      margin-bottom: 0.5rem;
    }
  }
`;

const resume = () => {
  return (
    <ResumeWrapper>
      <GlobalStyle />
      <ProgressBar />
      <Main>
        <Section>
          <h1>
            안녕하세요
            <br />
            박서현입니다.
          </h1>
          <p>
            1년 차 웹 프론트엔드 개발자 박서현입니다. 체계적으로 메모하고
            정리하는 습관을 바탕으로 프론트엔드 개발 커리어를 쌓아나가고
            있습니다.
          </p>
          <p>
            아름다운 인터페이스 구현을 바탕으로 체계적으로 설계된 UI/UX가
            사용자는 물론 프로덕트에 대한 긍정적인 인상을 남기는 데 기여한다고
            믿습니다. 주변 사람들과 함께 상호작용하는 것을 좋아하고 조화와
            화합을 중요시하며 나아가 팀 전체의 생산성 향상까지 이어지는 것에
            관심있습니다.
          </p>
        </Section>
        <h2>Work Experience</h2>
        <Article>
          <Header>
            <a href="http://ai-con.co.kr/" target={"_blank"}>
              <h3>AICON ↗️</h3>
            </a>
            <dl>
              <dd>Web Frontend Developer</dd>
            </dl>
            <dl>
              <dd>2022.7-2023.2</dd>
            </dl>
          </Header>
          <Content>
            <a href="https://www.caas.works/" target={"_blank"}>
              <h3>카스웍스 홈페이지 ↗️</h3>
            </a>
            <p>
              신입 프론트엔드 개발자로서 React를 기반으로 카스웍스의 프론트엔드
              개발에 참여했습니다.
            </p>
            <h4>Details</h4>
            <p>• 프론트엔드 인터페이스 구현</p>
            <h4>Tech Stacks</h4>
            <p>• React, TypeScript, GitHub, Styled Components</p>
          </Content>
        </Article>
        {/* <h2>Etc</h2>
        <Article>
          <Header>
            <h3></h3>
          </Header>
        </Article> */}
        <h2>Contact</h2>
        <Article>
          <Content>
            <li>
              <a href="https://github.com/westhyun" target="_blank">
                GitHub ↗️
              </a>
            </li>
            <li>
              <a href='mailto:"psh@parkseohyun.com' target="_blank">
                Email ↗️
              </a>
            </li>
          </Content>
        </Article>
      </Main>
    </ResumeWrapper>
  );
};

export default resume;
