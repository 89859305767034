import styled from "@emotion/styled";
import React, { FunctionComponent, useEffect, useState } from "react";
import SearchIconSvg from "./static/SearchIconSvg";

type SearchInputProps = {
  onSearch: (searchText: string) => void;
  project: boolean;
};

const SearchWrapper = styled.section`
  max-width: 640px;
  margin: 8rem 0 calc(2.2rem * 1.618);
  display: flex;
  justify-content: center;

  @media (max-width: 640px) {
    margin: 7.4rem 2rem 2.6rem;
  }
`;

const StyledSearchBar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: var(--inner-primary) var(--pad-inner);
`;

const SearchBar = styled.input<{ searchFocus: boolean; value: string }>`
  width: 100%;
  background-color: ${(props) =>
    props.value === "Side Project" && props.searchFocus
      ? "#282828"
      : "#f5f5f5"};
  border: ${(props) =>
    props.searchFocus ? "1.3px solid #282828" : "1px solid #282828"};
  border-radius: var(--rounded-xxlarge);
  color: ${(props) =>
    props.value === "Side Project" && props.searchFocus
      ? "#f5f5f5"
      : "#282828"};
  font-size: 16px;
  transition: background-color 0.6s ease, border 0.6s ease;
  padding: 10px 10px 10px 40px;
  outline: none;
  transition: all 0.3s ease-in-out;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Spoqa Han Sans Neo", "Helvetica Neue", sans-serif;
  flex: 1;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-left: 12px;
  /* 아이콘 클릭 방지 */
  pointer-events: none;
`;

const SearchInput: FunctionComponent<SearchInputProps> = ({
  onSearch,
  project,
}) => {
  const [searchText, setSearchText] = useState<string>("");
  const [searchFocus, setSearchFocus] = useState<boolean>(false);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    if (event.target.value.length >= 1) {
      onSearch(event.target.value);
    } else {
      onSearch("");
    }
  };

  useEffect(() => {
    if (searchText.length >= 1) {
      setSearchFocus(true);
    } else {
      setSearchFocus(false);
    }
  }, [searchText]);

  useEffect(() => {
    if (project === true) {
      setSearchFocus(true);
      setSearchText("Side Project");
    } else {
      setSearchFocus(false);
      setSearchText("");
    }
  }, [project]);

  return (
    <SearchWrapper>
      <StyledSearchBar>
        <IconWrapper>
          <SearchIconSvg
            fill={
              searchText == "Side Project" && searchFocus
                ? "#f5f5f5"
                : "#282828"
            }
          />
        </IconWrapper>
        <SearchBar
          type="text"
          value={searchText}
          onChange={handleSearch}
          searchFocus={searchFocus}
        />
      </StyledSearchBar>
    </SearchWrapper>
  );
};

export default SearchInput;
