import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import styled from "@emotion/styled";

interface Props {
  body: string;
}

const MdxStyle = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  word-break: break-all;
  font-weight: 400;
  font-size: 16px;
  width: 100%;

  pre[class*="language-"] {
    font-family: "Fira Code";
    overflow: auto;
    font-size: 0.9rem;
    text-shadow: none;
    background-color: #eeeeee;
    .token {
      background-color: inherit;
    }
  }

  code.css-0 {
    font-size: 0.85rem;
    font-family: "Fira Code";
    border-radius: 6px;
    background-color: #e1e1e1;
    padding: 0.2rem 0.4rem;
  }

  p {
    line-height: 28px;
  }

  img {
    max-width: 100%;
    margin: 0px auto;
    display: block;
  }

  h1,
  h2,
  h3 {
    font-weight: 800;
    margin-bottom: 12px;
  }

  * + h1 {
    margin-top: 3rem;

    @media (max-width: 640px) {
      margin-top: 2rem;
    }
  }

  * + h2 {
    margin-top: 30px;
  }

  * + h3 {
    margin-top: 10px;
  }

  hr + h1,
  hr + h2,
  hr + h3 {
    margin-top: 0;
  }

  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  h4 {
    font-size: 1.1rem;
  }

  blockquote {
    margin: 30px 0;
    padding: 5px 15px;
    border-left: 2px solid #282828;
    font-weight: 800;
  }

  ol,
  ul,
  li {
    margin-left: 15px;
    padding: 2px 0;
    line-height: 28px;
  }

  hr {
    border: 1px solid #e9e9e9;
    margin: 100px 0;
  }

  // Adjust Link Element Style
  a {
    text-decoration: underline;
    text-decoration-color: #bbeffd;
    text-decoration-thickness: 2px;
    background-color: rgba(187, 239, 253, 0.3);

    &:hover {
      background-color: rgba(187, 239, 253, 1);
    }
  }

  @media (max-width: 640px) {
    width: 100%;
    font-size: 16px;

    h1 {
      font-size: 1.2rem;
    }

    h2 {
      font-size: 1.1rem;
    }

    h3 {
      font-size: 1rem;
    }

    code {
      font-size: 16px;
    }
  }
`;

const MdxWrapper = styled(MdxStyle)`
  width: 100%;
  max-width: 765px;
  display: flex;

  @media (max-width: 640px) {
    padding: 0 2rem;
  }
`;

const StyledMdx = styled(MdxWrapper)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const PostContent = ({ body }: Props) => {
  return (
    <StyledMdx>
      <MDXRenderer>{body}</MDXRenderer>
    </StyledMdx>
  );
};

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
        }
        id
        body
      }
    }
  }
`;

export default PostContent;
